
  .table-activity {
    display: none;
    padding: 1rem;

    @media print {
      /* All your print styles go here */
        display: block;
    }
  } 

  .table-activity.show-pdf {
    display: block;
  }
